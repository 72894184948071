import React, { FC, ReactElement } from "react";

import { SvgIconProps } from "../Icon";

const IconLibrary: FC<SvgIconProps> = ({ className = "" }): ReactElement => (
  <svg viewBox="0 0 21 21" className={className}>
    <path d="M20.5968 4.76732C20.166 4.54732 19.7168 4.36398 19.2493 4.20815V15.2357C18.2043 14.8598 17.1318 14.6673 16.041 14.6673C14.2993 14.6673 12.576 15.1623 10.9993 16.1157V5.02398C9.51435 4.17148 7.80018 3.66732 5.95768 3.66732C4.31685 3.66732 2.76768 4.07065 1.40185 4.76732C1.09935 4.91398 0.916016 5.23482 0.916016 5.57398V16.6473C0.916016 17.179 1.34685 17.5548 1.83268 17.5548C1.97935 17.5548 2.12602 17.5182 2.27268 17.4448C3.38185 16.8673 4.62852 16.5007 5.95768 16.5007C7.85518 16.5007 9.60602 17.2523 10.9993 18.334C12.3927 17.2523 14.1435 16.5007 16.041 16.5007C17.3702 16.5007 18.6168 16.8673 19.726 17.454C19.8727 17.5273 20.0193 17.564 20.166 17.564C20.6427 17.564 21.0827 17.1882 21.0827 16.6565V5.57398C21.0827 5.23482 20.8993 4.91398 20.5968 4.76732ZM9.16602 15.2357C8.12102 14.8598 7.04852 14.6673 5.95768 14.6673C4.86685 14.6673 3.79435 14.8598 2.74935 15.2357V6.15148C3.76685 5.72065 4.83935 5.50065 5.95768 5.50065C7.05768 5.50065 8.14852 5.72982 9.16602 6.16065V15.2357ZM17.416 0.458984L12.8327 5.04232V13.7507L17.416 9.62565V0.458984Z" />
  </svg>
);

export default IconLibrary;
