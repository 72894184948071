import React, { FC, ReactElement } from "react";

import { SvgIconProps } from "../Icon";

const IconTelegram: FC<SvgIconProps> = ({ className = "" }): ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.965 0.50002C5.98195 0.509275 4.08327 1.30353 2.68431 2.70906C1.28535 4.11458 0.499978 6.01695 0.5 8.00002C0.5 9.98914 1.29018 11.8968 2.6967 13.3033C4.10322 14.7098 6.01088 15.5 8 15.5C9.98912 15.5 11.8968 14.7098 13.3033 13.3033C14.7098 11.8968 15.5 9.98914 15.5 8.00002C15.5 6.0109 14.7098 4.10324 13.3033 2.69672C11.8968 1.2902 9.98912 0.50002 8 0.50002C7.98833 0.499993 7.97667 0.499993 7.965 0.50002ZM11.0662 5.01502C11.1287 5.01377 11.2669 5.0294 11.3569 5.10252C11.4167 5.15446 11.4548 5.22694 11.4637 5.30564C11.4738 5.36377 11.4862 5.49689 11.4762 5.60065C11.3638 6.78689 10.875 9.66439 10.6262 10.9925C10.5212 11.555 10.3144 11.7431 10.1138 11.7613C9.67875 11.8019 9.34813 11.4738 8.92625 11.1975C8.26625 10.7644 7.89313 10.495 7.2525 10.0725C6.51188 9.58502 6.99188 9.31627 7.41375 8.87877C7.52437 8.76377 9.44313 7.01814 9.48063 6.86002C9.485 6.84002 9.48938 6.76627 9.44563 6.72752C9.40187 6.68877 9.33687 6.70189 9.29 6.71252C9.22375 6.72752 8.16937 7.42502 6.12687 8.80314C5.82688 9.00939 5.55625 9.1094 5.31313 9.10314C5.04563 9.09814 4.53062 8.95252 4.1475 8.82814C3.6775 8.67502 3.30437 8.59439 3.33687 8.33502C3.35375 8.20002 3.54 8.06189 3.895 7.92064C6.08125 6.96814 7.53875 6.34002 8.26875 6.03689C10.3513 5.17064 10.7844 5.02002 11.0662 5.01502Z"
      fill="currentcolor"
    />
  </svg>
);

export default IconTelegram;
